import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../Layout'
import SEO from '../../SEO'

import Hardcase from '../../components/Hardcase'

export const query = graphql`
  {
    file(relativePath: { eq: "musicgear/hardcase-page.jpg" }) {
      childImageSharp {
        resize {
          src
          width
          height
        }
      }
    }
  }
`

const HardcasePage = ({ data }) => (
  <Layout withPadding={false}>
    <SEO
      title="Hardcases"
      keywords={[
        'hardcase', 'pedalboard', 'music', 'music gear',
      ]}
      image={data.file.childImageSharp.resize}
    />
    <Hardcase />
  </Layout>
)

HardcasePage.propTypes = {
  data: shape({}).isRequired,
}

export default HardcasePage
