/* eslint-disable max-len */
import c from 'lodash.capitalize'
import { sizes, additionalOptions } from './data'

export const initState = () => {
  const widthId = sizes[0].id
  const depthId = sizes[0].depthOptions[0].id
  return {
    w: widthId,
    d: depthId,
  }
}

export const initDepth = widthId => {
  const selcetedOption = sizes.find(opt => opt.id === widthId)
  return selcetedOption.depthOptions[0]
}

export const getDepthOptions = widthId => {
  const selcetedOption = sizes.find(opt => opt.id === widthId)
  return selcetedOption.depthOptions.map(opt => ({
    ...opt,
    title: `${opt.title} ${opt.price}€`,
  }))
}

export const countTotal = ({
  widthId, depthId, additionalOpt = [],
}) => {
  if (!widthId || !depthId) return null

  const choosenWidth = sizes.find(s => s.id === widthId)
  const choosenDepth = choosenWidth.depthOptions.find(d => d.id === depthId)

  if (!choosenDepth) return null

  const sizePrice = choosenDepth.price

  const choosenAddOpts = additionalOptions.filter(
    opt => additionalOpt.includes(opt.id),
  )

  const additionalOptPrices = choosenAddOpts.reduce(
    (acc, opt) => {
      if (typeof opt.price === 'number') {
        return { ...acc, amount: acc.amount + opt.price }
      } if (typeof opt.price === 'string') {
        return {
          ...acc,
          text: [...acc.text, opt.price],
        }
      }

      return acc
    }, { amount: 0, text: [] },
  )

  const amount = sizePrice + additionalOptPrices.amount

  return {
    amount,
    text: additionalOptPrices.text,
  }
}

export const genDataForEmailDevi = ({
  widthId, depthId, additionalOpt = [], velourId = 'Noir', customSize,
}) => {
  const title = 'Hardcase'

  const selcetedWidth = sizes.find(s => s.id === widthId)
  const selectedDepth = selcetedWidth.depthOptions.find(o => o.id === depthId)
  const choosenAddOpts = additionalOptions.filter(opt => additionalOpt.includes(opt.id))
  const choosenAddOptsLabel = choosenAddOpts.map(o => o.label).join(', ') || 'Aucune'

  let data = []

  if (customSize) {
    data = [
      {
        label: 'Taille',
        value: 'taille sur mésure',
      },
    ]
  } else {
    data = [
      {
        label: 'Taille',
        value: `Largeur ${selcetedWidth?.title || 'Non definit'}, profondeur ${selectedDepth?.title || 'Non definit'}, prix ${selectedDepth?.price || 'Non definit'}€`,
      },
      {
        label: 'Options supplémentaires',
        value: choosenAddOptsLabel,
      },
      {
        label: 'Velour intérieur couleur',
        value: c(velourId),
      },
    ]
  }

  return { title, data }
}
