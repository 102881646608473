import React from 'react'
import { string, func } from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import c from 'lodash.capitalize'

import {
  ImagesCotainer, ItemContainer, ImgContainer, Name,
} from '../Cabs/Teintes/styles'

const query = graphql`
  {
    noir: file(relativePath: {
      eq: "velour/noir.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    jaune: file(relativePath: {
      eq: "velour/jaune.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    vert: file(relativePath: {
      eq: "velour/vert.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    rouge: file(relativePath: {
      eq: "velour/rouge.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Velour = ({ onClick, selectedId }) => {
  const data = useStaticQuery(query)
  const velour = Object.entries(data).map(([name, velourData]) => ({
    id: name,
    label: c(name),
    fixed: velourData.childImageSharp.fixed,
  }))

  return (
    <ImagesCotainer>
      {
        velour.map(({ id, label, fixed }) => (
          <ItemContainer
            onClick={() => { onClick(id) }}
            key={id}
          >
            <ImgContainer $isSelected={selectedId === id}>
              <Img fixed={fixed} />
            </ImgContainer>
            <Name>{label}</Name>
          </ItemContainer>
        ))
      }
    </ImagesCotainer>
  )
}

Velour.defaultProps = {
  selectedId: null,
}

Velour.propTypes = {
  onClick: func.isRequired,
  selectedId: string,
}

export default Velour
