/* eslint-disable max-len */
const depthM = {
  id: '210mm',
  title: '210mm',
}

const depthL = {
  id: '340mm',
  title: '340mm',
}

const depthXL = {
  id: '550mm',
  title: '550mm',
}

const size436 = {
  id: 's436',
  title: '436mm',
  depthOptions: [
    {
      ...depthM,
      price: 100,
    },
    {
      ...depthL,
      price: 120,
    },
  ],
}

const size536 = {
  id: 's536',
  title: '536mm',
  depthOptions: [
    {
      ...depthM,
      price: 110,
    },
    {
      ...depthL,
      price: 125,
    },
  ],
}

const size586 = {
  id: 's586',
  title: '586mm',
  depthOptions: [
    {
      ...depthM,
      price: 120,
    },
    {
      ...depthL,
      price: 130,
    },
    {
      ...depthXL,
      price: 165,
    },
  ],
}
const size636 = {
  id: 's636',
  title: '636mm',
  depthOptions: [
    {
      ...depthM,
      price: 130,
    },
    {
      ...depthL,
      price: 140,
    },
    {
      ...depthXL,
      price: 170,
    },
  ],
}
const size686 = {
  id: 's686',
  title: '686mm',
  depthOptions: [
    {
      ...depthL,
      price: 150,
    },
    {
      ...depthXL,
      price: 180,
    },
  ],
}
const size736 = {
  id: 's736',
  title: '736mm',
  depthOptions: [
    {
      ...depthL,
      price: 165,
    },
    {
      ...depthXL,
      price: 190,
    },
  ],
}
const size786 = {
  id: 's786',
  title: '786mm',
  depthOptions: [
    {
      ...depthL,
      price: 180,
    },
    {
      ...depthXL,
      price: 200,
    },
  ],
}
const size836 = {
  id: 's836',
  title: '836mm',
  depthOptions: [
    {
      ...depthL,
      price: 195,
    },
    {
      ...depthXL,
      price: 210,
    },
  ],
}

const size886 = {
  id: 's886',
  title: '886mm',
  depthOptions: [
    {
      ...depthL,
      price: 200,
    },
    {
      ...depthXL,
      price: 220,
    },
  ],
}

export const sizes = [
  size436,
  size536,
  size586,
  size636,
  size686,
  size736,
  size786,
  size836,
  size886,
]

export const additionalOptions = [
  {
    id: 'Poignée vox style noire',
    label: 'Poignée vox style noire +0€',
    price: 0,
  },
  {
    id: 'Poignée vox style crème',
    label: 'Poignée vox style crème +0€',
    price: 0,
  },
  {
    id: 'Poignées simili cuir',
    label: 'Poignées simili cuir (coloris : crème, dark blue, dark brown, light brown, red) +5€',
    price: 5,
  },
  {
    id: 'Revêtement extérieur tissu',
    label: 'Revêtement extérieur tissu ou tolex (prix sur demande)',
    price: 'Revêtement extérieur tissu ou tolex (prix sur demande)',
  },
  {
    id: 'Sérigraphie personnalisée (prix sur demande)',
    label: 'Sérigraphie personnalisée (prix sur demande)',
    price: 'Sérigraphie personnalisée (prix sur demande)',
  },
]

/**
largeur 436mm/profondeur M 210mm 100€
largeur 536mm/profondeur M 210mm 110€
largeur 586mm/profondeur M 210mm 120€
largeur 636mm/profondeur M 210mm 130€

largeur 436mm/profondeur L 340mm 120€
largeur 536mm/profondeur L 340mm 125€
largeur 586mm/profondeur L 340mm 130€
largeur 636mm/profondeur L 340mm 140€
largeur 686mm/ profondeur L 340mm 150€
largeur 736mm/ profondeur L 340mm 165€
largeur 786mm/ profondeur L 340mm 180€
largeur 836mm/ profondeur L 340mm 195€
largeur 836mm/ profondeur L 340mm 200€

largeur 586mm/profondeur XL 550mm 165€
largeur 636mm/profondeur XL 550mm 170€
largeur 686mm/ profondeur XL 550mm 180€
largeur 736mm/ profondeur XL 550mm 190€
largeur 786mm/ profondeur XL 550mm 200€
largeur 836mm/ profondeur XL 550mm 210€
largeur 836mm/ profondeur XL 550mm 220€
 */
