/* eslint-disable max-len */
import React from 'react'

import {
  ListContainer, ListTitle, IncludedList, ListLine,
} from '../Cabs/styles'

const List = () => (
  <ListContainer>
    <ListTitle>Le tarif de base inclut :</ListTitle>
    <IncludedList>
      <ListLine>Une peinture noire</ListLine>
      <ListLine>2 fermeture drow bolt</ListLine>
      <ListLine>1 poignée vox style noire ou crème</ListLine>
      <ListLine>2 charnières</ListLine>
      <ListLine>1 revêtement velour intérieur (voir liste)</ListLine>
    </IncludedList>
  </ListContainer>
)

export default List
