/* eslint-disable max-len */
import React from 'react'

import Checkbox from '../Checkbox'

import List from './List'
import Velour from './Velour'
import Button from '../Button'
import StyledLin from '../StyledLink'

import { Footer } from './styles'

import Form, {
  Dialog,
  genDevis,
} from '../Form'
import PageImage from '../PageImage'

import PricePreviw from '../Cabs/PricePreviw'
import {
  Content,
  Description,
  DevisTitle,
  DevisSubtitle,
  TitleSection,
  Section,
  CheckBoxWrapper,
} from '../Cabs/styles'
import { SelectContainer, SelectTitle, Select } from '../Pedalboards/style'

import { description } from '../Cabs/data'
import { sizes, additionalOptions } from './data'
import {
  getDepthOptions, initState, initDepth, countTotal, genDataForEmailDevi,
} from './helpers'

class Hardcase extends React.Component {
  state = {
    showForm: false,
    widthId: initState().w,
    depthId: initState().d,
    additionalOpt: [],
    velourId: 'noir',
    customSize: false,
  }

  onToggleForm = () => {
    const { showForm } = this.state
    this.setState({ showForm: !showForm, customSize: false })
  }

  onOpenCustomSizeForm = () => {
    this.setState({ customSize: true, showForm: true })
  }

  onChangeWidth = e => {
    const widthId = e.target.value
    const depthId = initDepth(widthId).id
    this.setState({
      widthId,
      depthId,
    })
  }

  onChangeDepth = e => {
    const depthId = e.target.value
    this.setState({ depthId })
  }

  onChangeAdditionalOpt = id => () => {
    const { additionalOpt } = this.state

    if (additionalOpt.includes(id)) {
      this.setState({ additionalOpt: [...additionalOpt].filter(c => c !== id) })
    } else {
      this.setState({ additionalOpt: [...additionalOpt, id] })
    }
  }

  onChangeVelour = velourId => {
    this.setState({ velourId })
  }

  render() {
    const {
      showForm, widthId, depthId, additionalOpt, velourId, customSize,
    } = this.state

    const totalPrice = countTotal({
      widthId, depthId, additionalOpt,
    })
    const showPrice = totalPrice?.amount

    const devisDataForMail = genDataForEmailDevi({
      widthId, depthId, additionalOpt, velourId, customSize,
    })
    const devisAsStringForMail = genDevis(
      devisDataForMail.title,
      devisDataForMail.data,
      customSize ? 'À definir' : totalPrice?.amount,
      customSize ? [] : totalPrice?.text,
    )
    return (
      <>
        {showPrice && (
          <PricePreviw
            amount={totalPrice?.amount}
            text={totalPrice?.text}
            openForm={this.onToggleForm}
          />
        )}
        <Dialog show={showForm} onClose={this.onToggleForm}>
          <Form
            title="Envoyer le devis"
            emailSubject="Devis Music Gear"
            withDevis
            devis={devisAsStringForMail}
          />
        </Dialog>
        <PageImage page="hardcase" />
        <Content>
          <Description>{description}</Description>
          <DevisTitle>Créez votre devis</DevisTitle>
          <List />
          <Section>
            <TitleSection>
              Choisissez la taille de votre hardcase
            </TitleSection>
            <DevisSubtitle>
              Toutes les dimensions données sont les dimensions intérieures des hardcases
            </DevisSubtitle>
            <SelectContainer>
              <SelectTitle>Largeur</SelectTitle>
              <Select
                value={widthId}
                options={sizes}
                onChange={this.onChangeWidth}
              />
            </SelectContainer>
            <SelectContainer>
              <SelectTitle>Profondeur</SelectTitle>
              <Select
                value={depthId}
                options={getDepthOptions(widthId)}
                onChange={this.onChangeDepth}
              />
            </SelectContainer>
          </Section>
          <Section>
            <SelectTitle>Options supplémentaires</SelectTitle>
            {
              additionalOptions.map(opt => (
                <CheckBoxWrapper key={opt.id}>
                  <Checkbox
                    label={opt.label}
                    checked={additionalOpt.includes(opt.id)}
                    onChange={this.onChangeAdditionalOpt(opt.id)}
                  />
                </CheckBoxWrapper>
              ))
            }
          </Section>
          <Section>
            <TitleSection>
              Choisissez le revêtement velour intérieur
            </TitleSection>
            <Velour selectedId={velourId} onClick={this.onChangeVelour} />
          </Section>
          <Section>
            <TitleSection>
              Besoin d’un pedalboard aux dimensions différentes de celles proposées ?
            </TitleSection>
            <Footer>
              <StyledLin to="/contact">
                <Button>Contactez-nous</Button>
              </StyledLin>
            </Footer>
          </Section>
        </Content>
      </>
    )
  }
}

export default Hardcase
